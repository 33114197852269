import { render, staticRenderFns } from "./MlPageTable.vue?vue&type=template&id=6f40d594&scoped=true&"
import script from "./MlPageTable.vue?vue&type=script&lang=js&"
export * from "./MlPageTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f40d594",
  null
  
)

export default component.exports