<template>
  <div class='ml-page-table'>
    <!--    <MSetTable ref='refMlPageTable'-->
    <!--               :showIndex='true'-->
    <!--               :offset='offset'-->
    <!--               :height='height'-->
    <!--               :tableData='dataList'-->
    <!--               :columns='columns'-->
    <!--               v-bind='$attrs'-->
    <!--               v-on='$listeners'-->
    <!--               :border='true'>-->
    <!--      <slot name='first-column' slot='first-column' />-->

    <!--      <slot />-->
    <!--      <slot name='handle-column' slot='handle-column' />-->
    <!--    </MSetTable>-->

    <el-table
      ref='elTable'
      :data='dataList'
      :height='height'
      :max-height="maxHeight||'auto'"
      v-bind='$attrs'
      v-on='$listeners'>
      <slot name='first-column'></slot>
      <slot name='second-column'></slot>
      <el-table-column
        v-if='showIndex'
        width='50'
        label='序号' header-align='center' align='center' fixed>
        <template slot-scope='scope'>
          <span>{{ offset + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <template v-for='(v,k) in columns'>
        <template v-if='!v.hide&&!v.slot'>
          <el-table-column
            :column-key='v.prop'
            :key='k'
            :prop="v.prop?v.prop:typeof(v.field)=='string'?v.prop:''"
            :label='v.label'
            :min-width='v.width'
            :show-overflow-tooltip='v?.overflow||false'
            :header-align="v.header_align?v.header_align:'center'"
            :align="v.align?v.align:'center'"
            :fixed='v.fixed'
            :sortable='v.sortable?v.sortable:false'>
            <template slot-scope='{row,column}'>
              <div v-if='!v.editable'>
                <div v-if="typeof(v.prop)=='string'">
                  <div v-if="v.type === 'image'">
                    <el-image
                      :src='row[column.property]' :alt='row[column.property]' style='width: 100%;height: 100%'
                      width='40px' fit='scale-down'
                      :preview-src-list='[row[column.property]]'>
                      <div slot='error' class='image-slot'>
                        暂无图片
                      </div>
                    </el-image>
                  </div>
                  <div v-else-if="v.type === 'images'&&Array.isArray(row[column.property])" style='display: flex;'>
                    <el-image
                      v-for='(image,image_index) in row[column.property]' :key='image_index+"image"'
                      :src='image.src' width='40px' fit='scale-down'
                      :preview-src-list='row[column.property].map(_=>{return _.src})' />
                  </div>
                  <span v-else-if="typeof(v.field)=='string'">
                      {{ row[v.field] || '' }}
                    </span>
                  <span v-else-if='v.icon'>
                       {{ row[column.property] }}
                       <i :title="v.editDialog?'双击修改':row[column.property]"
                          :style="v.editDialog?{cursor: 'pointer'}:''" :class='v.icon'></i>
                    </span>
                  <span v-else v-html='row[column.property]'></span>
                </div>
              </div>
              <editable-cell v-if='v.editable' :show-input='row.editMode'
                             v-model='row[column.property]' :is-selected='false'
                             @change='editCellVal(row,column.property)' :is-input-number='false'>
                                    <span slot='content'>
                                      <span>{{ row[column.property] }}</span>
                                      <i class='el-icon-edit default-edit-icon'></i>
                                    </span>
              </editable-cell>
            </template>

          </el-table-column>
        </template>
        <template v-else-if='!v.hide&&v.slot'>
          <slot :name='v.prop'/>
        </template>
      </template>

      <slot></slot>
      <slot name='handle-column'></slot>
    </el-table>

    <el-row class='ml-page-table__footer' style='margin-top: 10px;display: flex;align-items: center;'>
      <el-col :span='6'>
        <el-button title='点击进行设置列' icon='el-icon-setting' size='mini' @click='customColumnShow=true' />
        <slot name='footer' />
      </el-col>
      <el-col :span='18'>
        <m-pagination :limit.sync='pageSize'
                      :page.sync='currentPage'
                      :total.sync='total'
                      :hidden='total===0'
                      @pagination='getList'></m-pagination>
      </el-col>
    </el-row>
    <!--    列设置-弹窗  -->
    <MColumnSetting :visible.sync='customColumnShow' @userChange='columnSettingChange'
                    :column='allColumns' :table-name='tableName' @back='columnSettingBack' @save='columnSettingSave'
                    :editable-cols='editableCols' />
  </div>
</template>

<script>
import MSetTable from '@/components/common/MSetTable'
import MColumnSetting from '@/components/common/MColumnSetting'

export default {
  name: 'MlPageTable',
  components: { MColumnSetting, MSetTable },
  props: {
    showSlot: {
      type: Boolean,
      default: false
    },
    //全部列
    allColumns: {
      type: Array,
      default: () => [
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: true, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ]
    },
    showIndex: {
      default: false
    },
    offset: {
      default: 0
    },
    total: {
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    tableName: {
      type: String,
      required: true
    },
    editableCols: {
      type: Array,
      default: () => []
    },
    height: {
      type: [String, Number],
      default: ''
    },
    maxHeight: {
      type: [String, Number],
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  data() {
    return {
      tableLoading: false,
      // dataList: [],
      columns: [],//表格显示列
      pagingData: { current_page: 1, page_size: 10 },
      customColumnShow: false,
      isColSet: false
    }
  },
  methods: {
    editCellVal(row, field) {
      this.$emit('editCellVal', row, field)
    },
    clearSelection() {
      this.$refs.elTable.clearSelection()
    },
    /**
     * 获取table选中行
     * 父组件用
     */
    interfaceGetSelection: function() {
      return this.$refs.elTable.selection
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    getDefaultColumns() {
      // console.log(JSON.stringify(this.allColumns))
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        // console.log(JSON.stringify(this.allColumns))
        this.columns = [...this.allColumns]
      }
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      this.columns = [...userColumn]
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave(userColumn) {
      this.customColumnShow = false
      this.isColSet = true

    },
    // eslint-disable-next-line no-unused-vars
    columnSettingBack(userColumn) {
      this.isColSet = false

      this.getDefaultColumns()
    },
    async getList() {
      this.loading = true
      await this.$emit('getList')
      this.loading = false
    },
    rowClickEvent(row, column, event) {
      this.$emit('row-click', row, column, event)
    }
  }

}
</script>

<style scoped>

</style>
