<template>
  <div>
    <div
      v-loading='loading'
      element-loading-text='玩命加载中……'
      element-loading-spinner='el-icon-loading'>
      <el-table
        ref='elTable'
        :data='tableData'
        v-bind='$attrs'
        v-on='$listeners'>
        <slot name='first-column'></slot>
        <slot name='second-column'></slot>
        <el-table-column
          v-if="showIndex"
          width='50'
          label='序号' header-align='center' align='center'>
          <template slot-scope='scope'>
            <span>{{ offset + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for='(v,k) in columns'
          :key='k'
          :prop="v.field?v.field:typeof(v.value)=='string'?v.value:''"
          :label='v.title'
          :min-width='v.width'
          :show-overflow-tooltip='true'
          :header-align="v.header_align?v.header_align:'center'"
          :align="v.align?v.align:'center'"
          :sortable='v.sortable?v.sortable:false'>
          <template slot-scope='{row,column}'>
            <span v-html='row[column.property]'></span>
          </template>
        </el-table-column>
        <slot />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportTable',
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false
    },
    showIndex: {
      default: false
    },
    offset: {
      default: 0
    },
  }
}
</script>

<style scoped>

</style>